import "./polyfill.js";
import React from "react";
import ReactDOM from "react-dom";
import { unregister } from "./registerServiceWorker";
import { injectEnvsScript } from "./env";

injectEnvsScript()
  .then(() => import("./App"))
  .then(({ App }) => {
    ReactDOM.render(<App />, document.getElementById("root"));
  });
unregister();
