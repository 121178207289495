import { loadScript } from "tools/loadScript";

export let env = process.env;

export async function injectEnvsScript() {
  if (process.env.REACT_APP_ENVIRONMENT === "development") {
    return loadScript("/envs.js").then(() => {
      env = { ...env, ...window.env };
    });
  }
}
