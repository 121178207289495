let cachedPromises = new Map();

function injectScript(url, options) {
  let script = document.createElement("script");
  script.src = url;
  script.async = true;
  if (options.id) {
    script.id = options.id;
  }
  let headOrBody = document.head || document.body;
  headOrBody.appendChild(script);
  return script;
}

export function loadScript(url, options = {}) {
  if (!cachedPromises.has(url)) {
    let promise = new Promise((resolve, reject) => {
      if (typeof window === "undefined") {
        resolve(null);
        return;
      }

      let script;

      function removeEventListeners() {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
      }

      function onScriptLoad() {
        removeEventListeners();
        resolve();
      }

      function onScriptError() {
        script.remove();
        cachedPromises.delete(url);
        removeEventListeners();
        reject(new Error(`Script load error: ${url}`));
      }

      try {
        script =
          document.querySelector(`script[src^="${url}"]`) ||
          injectScript(url, options);

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);
      } catch (error) {
        script.remove();
        cachedPromises.delete(url);
        reject(error);
        return;
      }
    });
    cachedPromises.set(url, promise);
  }
  return cachedPromises.get(url);
}
